import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import arrow from "../assets/images/common/arrow-white.svg"

const byLines = [
  "Discover how entering the Scholastic Awards opens the door for recognition, scholarships, and more", 
  "Exhibitions and workshop for teens, professional development for educators", 
  "Empower creative teens and elevate youth voices with your support"
]

export default function MenuHero() {
  return (
    <StaticQuery
      query={graphql`
        query MenuHeroQuery {
          allWpMenu(filter: { slug: { eq: "hero" } }) {
            nodes {
              id
              name
              databaseId
              menuItems {
                nodes {
                  id
                  parentId
                  label
                  url
                  path
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section id="home-callouts" aria-label="Home Call Out Menu">
          <ul>
            {data.allWpMenu.nodes[0].menuItems.nodes.map((menuItem, i) => {
              return (
                <li key={i} role="none">
                  <Link to={menuItem.path}>
                    <span className="title"> {menuItem.label} </span>
                    <span className="text">
                      {byLines[i]}
                    </span>
                    <span className="arrow" style={{ backgroundImage: "url(" + arrow + ")" }}></span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </section>
      )}
    />
  )
}
