import React, { useState } from "react";
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import HomeCallout from "../components/home-callout"
import GalleryWidget from "../components/gallery-widget"

import imgFeature1 from "../assets/images/uploads/home-feature-sample-1.jpg"
import imgFeature2 from "../assets/images/uploads/home-feature-sample-2.jpg"
import imgFeature3 from "../assets/images/uploads/home-feature-sample-3.png"
import imgFeature4 from "../assets/images/uploads/home-feature-sample-4.jpg"

const Home = ({ data }) => {

  const [openSection, setOpenSection] = useState(false);
  const toggleSection = () => {
    setOpenSection(!openSection);
  };

  return (
    <Layout toggleSection={toggleSection} isHomePage>
            <Seo
        title="Home"
        description={data.site.siteMetadata.description}
      />

      <div id="home">
        <section id="home-hero">
          <div id="home-hero-overlay">
            <span>Supporting the Future of Creativity since 1923</span>
          </div>
        </section>

        <HomeCallout />



        <section id="home-features">
					<ul>
						<li>
							<OutboundLink href="https://newsroom.artandwriting.org">
								<img src={imgFeature1} width="800" height="800" alt="Art by First Lastname" />
								<span className="overlay"><span>Scholastic Awards News</span></span>
							</OutboundLink>
						</li>
						<li>
							<Link to="/gallery">
								<img src={imgFeature2} width="800" height="800" alt="Art by First Lastname" />
								<span className="overlay"><span>Award-Winning Works</span></span>
							</Link>
						</li>
						<li>
							<Link to="/scholarships">
								<img src={imgFeature3} width="800" height="800" alt="Art by First Lastname" />
								<span className="overlay"><span>Learn About Scholarships</span></span>
							</Link>
						</li>
						<li>
            <OutboundLink href="https://portal.artandwriting.org/">
								<img src={imgFeature4} width="800" height="800" alt="Art by First Lastname" />
								<span className="overlay"><span>Create Your Account</span></span>
							</OutboundLink>
						</li>
					</ul>
				</section>
      

        {/* <section id="home-gallery">
        <GalleryWidget />

        </section> */}
      </div>


    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        author
        description
        title
      }
    }
  }
`