import React, { Component } from "react"
import { Link } from "gatsby"
import GalleryCard from "./gallery/gallery-card"
import GalleryEndpoints from "./gallery/gallery-data"

const galleryQuery = GalleryEndpoints.galleryWidgetRandom

const writingColorArray = [
  "rgba(69, 148, 61, 1)",
  "rgba(243, 120, 54, .8)",
  "rgba(229, 81, 45, 1)",
  "rgba(46, 146, 153, 1)",
  "rgba(46, 147, 154, 1)",
  "rgba(44, 132, 201, 1)"
]

export class GalleryWidget extends Component {
  state = {
    works: [],
    endpoints: GalleryEndpoints
  }

  componentDidMount() {
    const worksArray = [];

    let randomPage = Math.floor(Math.random() * Math.floor(20));
    const galleryQuery = GalleryEndpoints.galleryRandomNoPage + String(0) 
    fetch(process.env.GATSBY_ORS_GALLERY + galleryQuery)
      .then(response => response.json())
      .then(responseJson => {
         let i = 0
          for(i = 0; i < 3; i++) {
            worksArray.push(responseJson.arts.works[i])
          }
          let randomNum = Math.floor(Math.random() * Math.floor(4));

          worksArray.splice(randomNum, 0, responseJson.writings.works[0]);

        this.setState({ works: worksArray })
      })
      .catch(error => {
        console.log("error", error)
      })
  }

  render() {
    return (
      
        <div className="gallery-widget">
          <h2>Gallery</h2>
          <ul>
          {this.state.works.map((work, index) => {
                const writingColor =
                  writingColorArray[
                    Math.floor(Math.random() * writingColorArray.length)
                  ]

                return (
                  <GalleryCard
                    key={work.id}
                    work={work}
                    writingColor={writingColor}
                  />
             
                )
              })}
          </ul>
       
          <Link to="/gallery" className="button">
            View More Works
          </Link>
        </div>
      
    )
  }
}

export default GalleryWidget
