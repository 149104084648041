import React, { Component } from "react"
import VgLogo from "../../assets/images/gallery-placeholders/videoGameDesign1.jpg"
import { Link, Location } from "@reach/router"

export class GalleryCard extends Component {
  state = {
    work: {},
    students: [],
  }

  getStudents = work => {
    let winners = [work.personName].concat(work.collaborators)
    this.setState({ students: winners })
  }

  componentDidMount() {

    if (this.props.work.categoryName === "Writing Portfolio") {
   
    this.setState(
      {
        work: this.props.work
      },
      () => {
        this.getStudents(this.props.work)
      }
    )
      
    } else {
      fetch(process.env.GATSBY_ORS_GALLERY + "/" + this.props.work.id, {
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then(response => response.json())
        .then(responseJson => {
          this.setState(
            {
              work: responseJson,
            },
            () => {
              this.getStudents(this.state.work)
            }
          )
        })
        .catch(error => {
          console.log("error", error)
        })
    }
  }
  
  render() {

    return (
      <Location>
        {({ location }) => (
          <div>
            {this.props.work.assetType == "video"  ? (
              <li className="gallery-art">
                <div className="video-content-box">
                  <Link
                    state={{
                      oldLocation: JSON.parse(JSON.stringify(location)),
                      title: this.props.work.title,
                      students: this.state.students,
                      work: this.state.work,
                    }}
                    to={"/gallery/" + this.props.work.id}
                  >
                    <img
                      src={this.props.work.thumbUrl}
                      alt={`${this.props.work.title} by ${this.props.work.personName}`}
                    />
                    <span className="overlay">
                      <span>{this.props.work.personName}</span>
                    </span>
                  </Link>
                </div>
              </li>
            ) : this.props.work.categoryName == "Video Game Design" ? (
              <li className="gallery-art">
                <div className="gallery-content-box">
                  <Link
                    state={{
                      oldLocation: JSON.parse(JSON.stringify(location)),
                      title: this.props.work.title,
                      students: this.state.students,
                      work: this.state.work,
                    }}
                    to={"/gallery/" + this.props.work.id}
                  >
                    <img
                      src={VgLogo}
                      alt={`${this.props.work.title} by ${this.props.work.personName}`}
                    />
                    <span className="overlay">
                      <span>{this.props.work.personName}</span>
                    </span>
                  </Link>
                </div>
              </li>
            ) : this.props.work.isArt == true || this.props.work.isFutureNew ? (
              <li className="gallery-art">
                <div className="gallery-content-box">
                  <Link
                    state={{
                      oldLocation: JSON.parse(JSON.stringify(location)),
                      title: this.props.work.title,
                      students: this.state.students,
                      work: this.state.work,
                    }}
                    to={"/gallery/" + this.props.work.id}
                  >
                    <img
                      src={this.props.work.thumbUrl}
                      alt={`${this.props.work.title} by ${this.props.work.personName}`}
                    />
                    <span className="overlay">
                      <span>{this.props.work.personName}</span>
                    </span>
                  </Link>
                </div>
              </li>
            ) : this.props.work.isWriting && this.props.work.isFutureNew ? (
              <li className="gallery-art">
                <div className="gallery-content-box">
                  <Link
                    state={{
                      oldLocation: JSON.parse(JSON.stringify(location)),
                      title: this.props.work.title,
                      students: this.state.students,
                      work: this.state.work,
                    }}
                    to={"/gallery/" + this.props.work.id}
                  >
                    <img
                      src={this.props.work.thumbUrl}
                      alt={`${this.props.work.title} by ${this.props.work.personName}`}
                    />
                    <span className="overlay">
                      <span>{this.props.work.personName}</span>
                    </span>
                  </Link>
                </div>
              </li>
            ) : (
              <li className="gallery-writing">
                <Link
                  state={{
                    oldLocation: JSON.parse(JSON.stringify(location)),
                    title: this.props.work.title,
                    students: this.state.students,
                    work: this.state.work,
                  }}
                  to={"/gallery/" + this.props.work.id}
                >
                  <span
                    className="text"
                    style={{ backgroundColor: this.props.writingColor }}
                  >
                    <div style={{ position: "absolute", top: "0", left: "0" }}>
                      {this.props.work.excerpt}
                    </div>
                    <span
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: 0,
                        background: `-moz-linear-gradient(90deg, rgba(255,255,255,0) 0%, ${this.props.writingColor} 82%)`,
                        background: `-webkit-linear-gradient(90deg, rgba(255,255,255,0) 0%, ${this.props.writingColor}82%)`,
                        background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${this.props.writingColor} 82%)`,
                      }}
                    ></span>
                  </span>
                  <span className="overlay">
                    <span>{this.props.work.personName}</span>
                  </span>
                </Link>
              </li>
            )}
          </div>
        )}
      </Location>
    )
  }
}

export default GalleryCard
